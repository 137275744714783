.EditLookForm {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  .imagesBlock {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    .largeImageWrapper {
      border-radius: 8px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #f7f8f9;
      height: 340px;
    }
    .smallImageWrapper {
      border-radius: 8px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #f7f8f9;
      height: 165px;
    }
  }

  .gridRow2 {
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 10px;
  }
  .flexRow {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    color: #002534;
  }
  .author {
    font-size: 18px;
    font-weight: bold;
    color: #002534;
  }
  .text {
    font-size: 16px;
    font-weight: normal;
    color: #002534;

    max-height: 160px;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      z-index: 2;
      cursor: pointer;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-button {
      background-color: #ffffff;
      height: 0px;
      width: 0px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      height: 30px;
      width: 10px;
      border-radius: 8px;
      background-color: #002534;
    }
  }
  .date-text {
    font-size: 18px;
    font-weight: normal;
    color: #809299;
  }
  .likes {
    font-size: 18px;
    font-weight: normal;
    color: #002534;
  }
  .controlsBlock {
    margin: 0 auto;
  }
  .tags {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
  }
  .tag {
    font-size: 12px;
    padding: 8px;
    color: #ffffff;
    text-align: center;
    background-color: #002534;
    border-radius: 6px;
    margin-right: 10px;
  }
}
