.EditOrAddProductForm {
  .gridCol2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    row-gap: 30px;
  }
  .gridCol3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
    row-gap: 30px;
  }
  .inputsBlockContainer {
    display: flex;
    align-items: center;
    min-height: 54px;
  }
  .translate-subtitle {
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 16px;
    color: #002534;
  }
  .sizeInputsBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .TextInput {
      max-width: 306px;
    }
  }
  .inputsBlock {
    display: flex;
    flex-direction: column;
    //display: grid;
    //grid-template-columns: 1fr;
    //row-gap: 20px;
  }
  .subTitle {
    font-family: "Source Sans Pro";
    font-size: 16px;
    color: #002534;
    text-transform: uppercase;
  }
  .sizesBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
    .sizeChecker {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      .Checkbox {
        margin-right: 30px;
      }
    }
  }
  .galleryBlock {
    padding-right: 3px;
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      z-index: 2;
      cursor: pointer;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-button {
      background-color: #ffffff;
      height: 0px;
      width: 0px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      height: 30px;
      width: 10px;
      border-radius: 8px;
      background-color: #002534;
    }
  }
  .controlsBlock {
    margin: 0 auto;
  }
}
