.TextareaInput {
  color: #002534;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  border: 2px solid #f7f8f9;
  background-color: #f7f8f9;
  border-radius: 8px;
  padding: 10px;
  outline: none;
  width: inherit;
  resize: none;
  &::placeholder {
    color: #85979f;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }
  &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    z-index: 2;
    cursor: pointer;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-button {
    background-color: #ffffff;
    height: 0px;
    width: 0px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    height: 30px;
    width: 10px;
    border-radius: 8px;
    background-color: #002534;
  }
}
