.Header {
  align-items: center;
  display: grid;
  grid-template-columns: 300px 1fr;
  &__logo {
    height: 50px;
    background-color: #002534;
    padding-left: 30px;
    padding: 15px;

    svg {
      height: 100%;
      width: auto;
    }
  }

  &__controlsBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
    padding: 15px;
    &__userInfo {
      text-transform: capitalize;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      color: #002534;
      margin-right: 50px;
    }
  }
}
