.Search {
  position: relative;
  font-family: "Source Sans Pro";
  outline: none;
  &__arrow {
    width: 15px;
    height: 15px;
    margin-top: 5px;
  }
  &:active ul,
  &:focus ul {
    display: block;
  }
  &:active &__arrow,
  &:focus &__arrow {
    transform: rotate(180deg);
  }
  &:active .inputBlock,
  &:focus .inputBlock {
    display: block;
    border: 1px solid #c4cbce;
  }

  .inputBlock {
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    border: 1px solid #c4cbce;
    z-index: 1;
    input {
      cursor: pointer;
      font-family: "Source Sans Pro";
      width: 80%;
      background-color: transparent;
      color: #002534;
      font-weight: 500;
      font-size: 16px;
      border: 0px;
      outline: none;
      &::placeholder {
        color: #c4cbce;
        font-weight: 500;
        font-size: 16px;
      }
    }
    svg {
      z-index: 2;
      float: right;
      margin-top: 2px;
      path {
        fill: #002534;
      }
    }
  }
  .clearBtn {
    width: 15px;
    height: 15px;
    z-index: 2;
    margin-top: 4px;
    path {
      fill: #002534;
      cursor: pointer;
    }
  }
  ul {
    margin: 0px;
    padding: 0px;
    z-index: 10;
    font-family: "Source Sans Pro";
    display: none;
    max-height: 350px;
    overflow: auto;
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    -webkit-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      width: 6px;
      height: 3px;
      border-radius: 3px;
      background-color: #e4e6ea;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-button {
      background-color: #96a0b0;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      height: 30px;
      width: 10px;
      border-radius: 8px;
      background-color: #ccd1d8;
    }
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #002534;
      padding: 1rem;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      &:active,
      &:focus {
        background-color: #fbfbfb;
      }
    }
  }
}
