.modal {
  background-color: #000000; /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding-top: 1%;
  .modal-content {
    position: relative;
    width: auto;
    max-width: 40%;
    min-width: 450px;
    margin: auto;
    border-radius: 8px;
    z-index: 30;
    .close {
      cursor: pointer;
      color: #aaaaaa;
      font-size: 24px;
      font-weight: bold;
      line-height: 14px;
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}
