.Button {
  border: 1px solid #002534;
  border-radius: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #002534;
  background: transparent;
  outline: none;
  min-width: 155px;
  cursor: pointer;
  &--filled {
    background: #002534;
    color: #f5f5f5;
  }
  &:hover {
    color: #f6a331;
    svg {
      rect,
      path {
        fill: #f6a331;
      }
    }
  }
  &:disabled {
    color: #d6d9da;
    background-color: #f5f5f5;
    border: 2px solid #d6d9da;
  }
}
