@import "../../assets/fonts/stylesheet.scss";

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Source Sans Pro";
  background: #f5f5f5;
}

.error-message-notice {
  -webkit-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
  width: 100%;
  z-index: 100;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message-notice-content {
  -webkit-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
  padding: 20px;
  opacity: 1;
  top: 40px;
  position: fixed;
  padding: 15px;
  min-width: 200px;
  background-color: #ffffff;
  border-radius: 4px;
  font-weight: 400;
  color: #3a3b43;
  display: flex;
  justify-content: space-around;
  align-items: center;
  animation-name: MessageMoveOut;
  animation-duration: 0.5s;
}
@keyframes MessageMoveOut {
  0% {
    top: -100px;
    opacity: 0;
  }
  100% {
    top: 40px;
    opacity: 1;
  }
}

.Layout {
  background: #f5f5f5;
  display: grid;
  grid-template-columns: 300px 1fr;
  height: calc(100% - 80px);
}

.page-content {
  padding: 20px;
}

.table-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.red-text {
  color: #da142c;
}
.green-text {
  color: #19c267;
}
.underline-text {
  text-decoration: underline;
  &:hover {
    text-decoration: inherit;
  }
}

.translate-flex-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.translate-flex-col {
  display: flex;
  flex-direction: column;
}
.translate-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #002534;
}
.translate-count {
  color: #002534;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.translate-language {
  color: #85979f;
  font-weight: 600;
  font-size: 16px;
}

.m1 {
  margin: 10px;
}
.m2 {
  margin: 20px;
}

.m3 {
  margin: 30px;
}
.m4 {
  margin: 40px;
}

.mr1 {
  margin-right: 10px;
}
.mr2 {
  margin-right: 20px;
}

.mr3 {
  margin-right: 30px;
}
.mr4 {
  margin-right: 40px;
}

.ml1 {
  margin-left: 10px;
}
.ml2 {
  margin-left: 20px;
}
.ml3 {
  margin-left: 30px;
}
.ml4 {
  margin-left: 40px;
}

.mt1 {
  margin-top: 10px;
}
.mt2 {
  margin-top: 20px;
}
.mt3 {
  margin-top: 30px;
}
.mt4 {
  margin-top: 40px;
}

.mb1 {
  margin-bottom: 10px;
}
.mb2 {
  margin-bottom: 20px;
}
.mb3 {
  margin-bottom: 30px;
}
.mb4 {
  margin-bottom: 40px;
}

.p1 {
  padding: 10px;
}
.p2 {
  padding: 20px;
}
.p3 {
  padding: 30px;
}

.pl1 {
  padding-left: 10px;
}
.pl2 {
  padding-left: 20px;
}
.pl3 {
  padding-left: 30px;
}

.pr1 {
  padding-right: 10px;
}
.pr2 {
  padding-right: 20px;
}
.pr3 {
  padding-right: 30px;
}

.pt1 {
  padding-top: 10px;
}
.pt2 {
  padding-top: 20px;
}
.pt3 {
  padding-top: 30px;
}

.pb1 {
  padding-bottom: 10px;
}
.pb2 {
  padding-bottom: 20px;
}
.pb3 {
  padding-bottom: 30px;
}
