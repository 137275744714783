.Pagination {
  color: #c2c2c2;
  font-size: 16px;
  margin-top: 40px;
  float: right;
  button {
    background-color: transparent;
    outline: none;
    border: 0px;
    cursor: pointer;
    font-size: 16px;
    font-family: Source Sans Pro;
    color: #c2c2c2;

    &.active {
      color: #002534;
    }
    &:hover {
      color: #f6a331;
    }
  }
}
