.EditOrAddCategoryForm {
  .childCategories {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    column-gap: 20px;
    align-items: center;
    .categoryChild {
      padding-left: 5px;
      &::before {
        width: 12px;
        height: 12px;
        content: "";
        background-color: #f6a331;
        border-radius: 50%;
        display: inline-block;
        margin-right: 13px;
        margin-bottom: -1px;
      }
    }
  }
}
