.TagsInput {
  position: relative;
  font-family: "Source Sans Pro";
  outline: none;
  &__arrow {
    width: 15px;
    height: 15px;
    margin-top: 8px;
    path {
      fill: #c6ced2;
    }
  }
  &:active ul,
  &:focus ul {
    display: block;
  }
  &:active &__arrow,
  &:focus &__arrow {
    transform: rotate(180deg);
  }
  &:active .inputBlock,
  &:focus .inputBlock {
    display: block;
    border: 1px solid #f7f8f9;
  }

  .inputBlock {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    padding-left: 16px;
    height: 30px;
    padding-right: 16px;
    border: 1px solid #f7f8f9;
    background-color: #f7f8f9;
    z-index: 1;
    input {
      cursor: pointer;
      width: 80%;
      height: 100%;
      background-color: transparent;
      color: #002534;
      font-family: "Source Sans Pro";
      font-weight: normal;
      font-size: 18px;
      border: 0px;
      outline: none;
      &::placeholder {
        color: #85979f;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
      }
    }
    svg {
      cursor: pointer;
      z-index: 1;
      float: right;
      border-radius: 50%;
      background-color: #002534;
      padding: 5px;
      width: 15px;
      height: 15px;
      margin-top: 3px;
      &:hover {
        path {
          fill: #f6a331;
        }
      }
    }
  }
  .tagsBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    .tag {
      background: #002534;
      border-radius: 6px;
      color: #ffffff;
      font-weight: normal;
      font-size: 12px;
      padding: 6px;
      padding-left: 10px;
      padding-right: 8px;
      margin-bottom: 10px;
      margin-right: 10px;
      line-height: 20px;
      &:last-child {
        margin-right: 0px;
      }
      .removeBtn {
        cursor: pointer;
        width: 16px;
        height: 16px;
        background: #ffffff;
        margin-left: 8px;
        border-radius: 50%;
        padding: 3px;
        width: 8px;
        height: 8px;
        margin-bottom: -3px;
        path {
          fill: #002534;
        }
      }
    }
  }
}
