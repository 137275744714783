.SearchInput {
  position: relative;
  font-family: "Source Sans Pro";
  outline: none;
  &__arrow {
    width: 15px;
    height: 15px;
    margin-top: 8px;
    path {
      fill: #c6ced2;
    }
  }
  &:active ul,
  &:focus ul {
    display: block;
  }
  &:active &__arrow,
  &:focus &__arrow {
    transform: rotate(180deg);
  }
  &:active .inputBlock,
  &:focus .inputBlock {
    display: block;
    border: 1px solid #f7f8f9;
  }

  .inputBlock {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    padding-left: 16px;
    height: 30px;
    padding-right: 16px;
    border: 1px solid #f7f8f9;
    background-color: #f7f8f9;
    z-index: 1;
    width: calc(100% - 30px);
    input {
      cursor: pointer;
      width: 80%;
      height: 100%;
      background-color: transparent;
      color: #002534;
      font-family: "Source Sans Pro";
      font-weight: normal;
      font-size: 18px;
      border: 0px;
      outline: none;
      &::placeholder {
        color: #85979f;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
      }
    }
    svg {
      z-index: 2;
      float: right;
      margin-top: 6px;
    }
  }
  .clearBtn {
    width: 13px;
    height: 13px;
    z-index: 2;
    margin-top: 9px;
    path {
      fill: #002534;
      cursor: pointer;
    }
  }
  ul {
    margin: 0px;
    padding: 0px;
    z-index: 10;
    font-family: "Source Sans Pro";
    display: none;
    max-height: 350px;
    overflow: auto;
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    -webkit-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 8px 19px -7px rgba(0, 0, 0, 0.75);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      z-index: 2;
      cursor: pointer;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-button {
      background-color: #ffffff;
      height: 0px;
      width: 0px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      height: 30px;
      width: 10px;
      border-radius: 8px;
      background-color: #002534;
    }
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #002534;
      padding: 1rem;
      font-weight: 500;
      font-size: 18px;
      cursor: pointer;
      border-bottom: 1px solid #e5e6e6;
      &:last-child {
        border: 0px;
      }
      &:active,
      &:hover,
      &:focus {
        background-color: #002534;
        color: #ffffff;
      }
    }
  }
}
