.SizesDetailPage {
  background-color: #ffffff;
  margin: 20px;
  border-radius: 8px;
  padding: 30px;
  .formItems {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    column-gap: 40px;
  }
  .controlButtons {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin: 0 auto;
    width: 300px;
    button {
      margin-bottom: 10px;
    }
  }
}
