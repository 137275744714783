.EditOrAddArticleForm {
  .gridCol2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 30px;
  }
  .inputsBlock {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
  .imagesBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 15px;
    column-gap: 15px;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 5px;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
      z-index: 2;
      cursor: pointer;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-button {
      background-color: #ffffff;
      height: 0px;
      width: 0px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      height: 30px;
      width: 10px;
      border-radius: 8px;
      background-color: #002534;
    }
  }
  .controlsBlock {
    margin: 0 auto;
  }
}
