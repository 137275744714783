.Stepper {
  display: flex;
  justify-content: row;
  align-items: center;
  justify-content: center;
  .stepper-btn {
    cursor: pointer;
    outline: none;
    border: 0px;
    background: #ffffff;
    border-radius: 8px;
    color: #002534;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      svg path {
        fill: #f6a331;
      }
      color: #f6a331;
    }
  }
  .stepper-btn-right {
    margin-left: 25px;
  }
  .stepper-btn-left {
    margin-right: 25px;
  }
}
