.Sidebarmenu {
  padding: 0px;
  margin: 0px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 16px;
  color: #ffffff;
  position: relative;
  background-color: #002534;
}

.Sidebarmenu::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.Sidebarmenu::-webkit-scrollbar-button {
  background-color: transparent;
  height: 0px;
}

.Sidebarmenu::-webkit-scrollbar-thumb {
  height: 30px;
  background-color: #f6a331;
}
