.Sidebarmenu__submenu--active {
  background-color: #ffffff;
}

.sidebarmenu-submenu__content {
  background-color: #002534;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0px;
}

.Sidebarmenu__submenu > .sidebarmenu-submenu__content {
  max-height: 0;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}
.Sidebarmenu__submenu--active > .sidebarmenu-submenu__content {
  max-height: 450px;
  -webkit-overflow-scrolling: touch;
  transition: max-height 0.2s ease-in;
}
.Sidebarmenu__submenu--active > .Sidebarmenu__item {
  background-color: #ffffff;
  color: #002534;
}
.Sidebarmenu__submenu--active > .Sidebarmenu__item > .sidebarmenu-item__arrow {
  padding-left: 30px;
  margin-top: 9px;
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.Sidebarmenu__submenu > .sidebarmenu-submenu__content > .Sidebarmenu__item {
  background-color: #001a24;
}
