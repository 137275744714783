.GridTable {
  width: 100%;
  max-height: 600px;
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 10px;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    z-index: 2;
    cursor: pointer;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-button {
    background-color: #ffffff;
    height: 0px;
    width: 0px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    height: 30px;
    width: 10px;
    border-radius: 8px;
    background-color: #002534;
  }
  table {
    background-color: #ffffff;
    border-radius: 8px;
    border-spacing: 0px;
    width: 100%;
    thead {
      tr {
        th {
          padding: 1rem;
          font-family: "Source Sans Pro";
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          color: #cbd3d6;
        }
      }
    }
    tbody {
      display: block;
      tr {
        border-bottom: 1px solid #eeeff2;

        &:hover {
          background-color: #f7f7f7;
        }
      }
    }

    th {
      text-align: left;
      color: #002534;
      font-size: 16px;
      padding-bottom: 1rem;
    }
    tr {
      display: table;
      width: 100%;
      cursor: pointer;

      & td:last-child {
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
      }
      & td:first-child {
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
      }
    }
    td {
      padding: 1rem;
      vertical-align: middle;
      font-weight: 500;
      font-size: 16px;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .GridTable {
    th {
      font-size: 12px;
    }

    tbody {
      padding-right: 0px;
      td {
      }
    }
  }
}
