.Checkbox {
  min-height: 28px;
  label {
    font-size: 14px;
    position: relative;
    cursor: pointer;

    &:before {
      border-radius: 2px;
      position: absolute;
      left: 0;
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      top: 4px;
      background: #ffffff;
      line-height: 17px;
      font-size: 14px;
      font-weight: 800;
      border: 1.5px solid #cbd3d6;
      text-align: center;
    }
  }

  input[type="checkbox"] {
    display: none;
    &:checked + label:before {
      content: "\2713";
      background-color: #f6a331;
      border: 1.5px solid #f6a331;
      color: #ffffff;
    }
  }
}
