.EditableTable {
  width: 100%;
  max-height: 600px;
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 10px;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    z-index: 2;
    cursor: pointer;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-button {
    background-color: #ffffff;
    height: 0px;
    width: 0px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    height: 30px;
    width: 10px;
    border-radius: 8px;
    background-color: #002534;
  }
  table {
    background-color: #ffffff;
    border-spacing: 0px;
    border: 1px solid #002534;
    width: 100%;

    tbody {
      td {
        &.highlight {
          border-color: #f6a331;
        }
        &:hover {
          background-color: #f7f7f7;
        }
      }
    }
    tr {
      &.highlight {
        td {
          border-color: #f6a331;
        }
      }

      display: table;
      width: 100%;
      cursor: pointer;
    }

    th {
      color: #002534;
      padding: 0.5rem;
      font-size: 18px;
      border: 0px;
      display: inline-block;
      text-align: left;
      padding-bottom: 40px;
    }

    td {
      vertical-align: top;
      textarea {
        font-weight: 500;
        font-size: 18px;
        font-family: Source Sans Pro;
        color: #002534;
        padding: 0.5rem;
        border: 0px;
        outline: none;
        width: 100%;
        background-color: transparent;
        overflow: hidden;
        height: auto;
        resize: none;
        line-height: 20px;
        box-sizing: border-box;
      }
    }
  }

  .controlsBlock {
    button {
      margin-right: 15px;
    }
  }
}

@media screen and (max-width: 991px) {
  .EditableTable {
    tbody {
      padding-right: 0px;
    }
  }
}
