.ImageInput {
  width: 100%;
  height: 240px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #cbd3d6;
  cursor: pointer;
  .imagePresentBlock {
    position: relative;
    color: #002534;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .addBlock {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      width: 115px;
      svg {
        margin-bottom: 10px;
        rect {
          fill: #002534;
        }
        width: 24px;
        height: 24px;
      }
    }
    .clearBlock {
      background-color: #ffffff;
      padding: 6px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 5px;
      bottom: 5px;
      path {
        fill: #002534;
      }
      &:hover {
        path {
          fill: #f6a331;
        }
      }
    }
  }

  input {
    display: none;
  }
}
