.EmptyTableBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 8px;
  min-height: 340px;
  .Text {
    font-size: 16px;
    font-family: "Source Sans Pro";
    font-weight: 400;
    color: #ccd1d8;
  }
}
