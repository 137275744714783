.Form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  color: #002534;
  .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 32px;
  }
}
