.AuthPage {
  width: 100%;
  height: 100%;
  background-color: #002534;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__inputsBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    margin-top: 35px;
    :last-child {
      margin-top: 25px;
    }
  }

  svg {
    margin-bottom: 30px;
    width: 200px;
    height: 200px;
  }
}
