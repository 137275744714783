.Sidebarmenu__item {
  cursor: pointer;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #002534;
}
.Sidebarmenu__item:hover,
.Sidebarmenu__item--highlighted {
  background-color: #001a24;
  color: #f6a331;
}

.sidebarmenu-item__title {
  font-family: Source Sans Pro;
  font-size: 18px;
  padding: 1rem;
  padding-left: 2rem;
}

.sidebarmenu-item__arrow {
  margin-top: 1px;
  padding-right: 30px;
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
.Sidebarmenu__item hr {
  color: #001a24;
}
