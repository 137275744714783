.NumInput {
  color: #002534;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  height: 30px;
  border: 2px solid #f7f8f9;
  background-color: #f7f8f9;
  border-radius: 8px;
  padding: 10px;
  outline: none;
  width: calc(100% - 20px);
  &::placeholder {
    color: #85979f;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }
}
