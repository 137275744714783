.EditOrAddShopForm {
  &__inputsBlock {
    display: grid;
    grid-template-columns: 1fr 425px;
    column-gap: 60px;
    &__shopAddressName,
    &__shopName {
      width: 350px;
    }
    &__searchName {
      width: calc(100% - 13px);
    }
  }
  &__addressesForm {
    display: grid;
    grid-template-columns: 1fr 220px 50px;
    column-gap: 10px;
    &__coordinates {
      background-color: #f7f8f9;
      display: grid;
      grid-template-columns: 100px 100px;
      column-gap: 20px;
      border-radius: 8px;
    }
  }

  &__plusBtn {
    cursor: pointer;
    outline: none;
    border: 0px;
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;
    line-height: 10px;
    background-color: #f7f8f9;
    border-radius: 8px;
    svg {
      rect {
        fill: #002534;
      }
    }

    &:hover {
      svg {
        rect {
          fill: #f6a331;
        }
      }
    }
    &:disabled {
      svg {
        rect {
          fill: #d6d9da;
        }
      }
    }
  }

  &__buttonsBlock {
    width: 460px;
    margin: 0 auto;
  }
  .tableTitle {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #002534;
    margin-left: 15px;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .removeBtn {
    width: 10px;
    height: 10px;
    path {
      fill: #494949;
    }
    &:hover {
      path {
        fill: #f6a331;
      }
    }
  }
}
